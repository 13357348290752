<template>
  <b-modal
    id="form-sidebar"
    size="lg"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title="Domínios do Software"
    right
    shadow
    @hidden="close"
    @hide="close"
    @change="(val) => $emit('update:is-active', val)"
  >
    <b-card class="px-0 m-0 p-0">
      <b-row>
        <b-col class="text-right">
          <b-button
            variant="primary"
            @click="newModel"
          >
            <FeatherIcon icon="PlusIcon" />
            &nbsp;<span class="text-nowrap">Domínio</span>
          </b-button>
        </b-col>
      </b-row>
      <Grid
        :fields="fields"
        :items="items"
        edit-button-label="edit"
        new-button-label="new"
        :show-search="false"
        @onChangePage="getData"
      >
        <template
          #cell(action)="data"
          style="width: 20%"
        >
          <div class="px-1 text-center">
            <i2-icon-button
              icon="EditIcon"
              variant="outline-primary"
              class="mr-1"
              @click="setCurrentModel(data.item)"
            />
            <i2-icon-button
              fa-icon="fa-trash"
              variant="outline-danger"
              class="mr-1"
              @click="onDelete(data.item)"
            />

          </div>
        </template>
      </Grid>
    </b-card>
    <web-software-domain-form
      :is-active.sync="isActivated"
      :uuid="currentModel"
      :web-software-uuid="webSoftwareUuid"
      @onSave="onSave()"
      @onCancel="onCancel()"
    />
  </b-modal>
</template>

<script>

import WebSoftwareDomainForm from '@/views/websoftware/WebSoftwareDomainForm.vue'

export default {
  name: 'WebSoftwareDomainList',
  components: { WebSoftwareDomainForm },
  props: {
    webSoftwareUuid: {
      required: true,
      type: String,
    },
    open: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      search: {
        search: '',
      },
      fields: [
        {
          key: 'domain',
          label: 'Domínio',
          class: 'w-40',
        },

        {
          key: 'action',
          label: 'Ações',
          class: 'w-20 text-center',
        },
      ],
      currentModel: '',
      items: [],
      isActive: false,
      isActivated: false,
    }
  },
  watch: {
    open(value) {
      this.isActive = value
      if (value) {
        this.getData()
      }
    },
  },
  methods: {
    newModel() {
      this.isActivated = true
    },
    async getData(page) {
      this.items = await this.$search.get(
        `web-software/${this.webSoftwareUuid}/domains`,
        page,
        this.search,
        '',
      )
    },
    setCurrentModel(model) {
      this.isActivated = true
      this.currentModel = model.uuid
    },
    onDelete(model) {
      const route = `web-software-domains/${model.uuid}`
      this.$apiDelete.execute(
        route,
        'Confirma a exclusão do domínio?',
        'Excluir',
        () => {
          this.$notify.success('Domínio removido com sucesso.')
          this.getData()
        },
      )
    },
    onSave() {
      this.getData(1)
      this.isActivated = false
      this.currentModel = ''
    },
    onCancel() {
      this.isActivated = false
      this.currentModel = ''
    },
    close() {
      this.currentModel = ''
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
