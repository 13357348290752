<template>
  <b-container>
    <page-header name="WebSoftware">
      <b-button
        variant="primary"
        @click="newModel"
      >
        <FeatherIcon icon="PlusIcon" />
        &nbsp;<span class="text-nowrap">Adicionar</span>
      </b-button>
    </page-header>
    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getData"
    >
      <template v-slot:search>
        <b-row class="p-0 pl-1">
          <b-col
            md="10"
            class="p-0 pr-1"
          >
            <b-form-input
              v-model="search.search"
              class="filter-input"
              placeholder="Pesquisar por Nome"
              name="nome"
              @keyup.enter="getData"
            />
          </b-col>
          <b-col
            sm="12"
            md="2"
          >
            <b-button
              variant="secondary"
              type="button"
              @click="getData"
            >
              <FeatherIcon icon="SearchIcon" />
              <span
                class="text-nowrap"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-1 text-center">
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            class="mr-1"
            @click="setCurrentModel(data.item)"
          />
          <i2-icon-button
            icon="CloudIcon"
            variant="outline-primary"
            class="mr-1"
            @click="openModalDomains(data.item)"
          />
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="onDelete(data.item)"
          />

        </div>
      </template>
    </Grid>
    <web-software-form
      :is-active.sync="isActivated"
      :uuid="currentModel"
      @onSave="onSave()"
      @onCancel="onCancel()"
    />
    <web-software-domain-list
      :open="isOpenModalDomains"
      :web-software-uuid="currentSoftwareUuid"
      @close="closeModalDomains"
    />
  </b-container>

</template>

<script>

import WebSoftwareForm from '@/views/websoftware/WebSoftwareForm.vue'
import WebSoftwareDomainList from '@/views/websoftware/WebSoftwareDomainList.vue'

export default {
  name: 'WebSoftwareList',
  components: { WebSoftwareDomainList, WebSoftwareForm },
  data() {
    return {
      search: {
        search: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-80',
        },

        {
          key: 'action',
          label: 'Ações',
          class: 'w-20 text-center',
        },
      ],
      currentModel: '',
      items: [],
      isActivated: false,
      currentSoftwareUuid: '',
      isOpenModalDomains: false,
    }
  },
  async mounted() {
    await this.getData(1)
  },
  methods: {
    newModel() {
      this.isActivated = true
    },
    async getData(page) {
      this.items = await this.$search.get(
        'web-software',
        page,
        this.search,
        '',
      )
    },

    setCurrentModel(model) {
      this.isActivated = true
      this.currentModel = model.uuid
    },

    onDelete(model) {
      const route = `web-software/${model.uuid}`
      this.$apiDelete.execute(
        route,
        'Confirma a exclusão dw WebSoftware',
        'Excluir',
        () => {
          this.$notify.success('WebSoftware removido com sucesso.')
          this.getData()
        },
      )
    },
    onSave() {
      this.getData(1)
      this.isActivated = false
      this.currentModel = ''
    },
    onCancel() {
      this.isActivated = false
      this.currentModel = ''
    },
    openModalDomains(model) {
      this.currentSoftwareUuid = model.uuid
      this.isOpenModalDomains = true
    },
    closeModalDomains() {
      this.currentSoftwareUuid = ''
      this.isOpenModalDomains = false
    },
  },
}
</script>

<style scoped>

</style>
