<template>
  <b-sidebar
    id="form-sidebar"
    ref="sideBar"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title=""
    right
    shadow
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #header="{hide}" >
      <div class="d-flex justify-content-between align-items-center w-100" >
        <h5 class="mb-0">
          Cadastro de WebSoftware
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer float-right"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{hide}" >
      <div class="p-1" >
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>

    <template #default="{ hide }">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
                   <b-row>
           <b-col
             sm="12"
             md="12"
           >
                         <i2-form-input
                 v-model="model.name"
               
                rules="required"
                label="Nome"
                placeholder=""
                name="name"
             />
           </b-col>
         </b-row>

        </b-form>

      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'WebSoftwareForm',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      model: {
        uuid: null,
        name: '',

      },
    }
  },
  watch: {
    uuid(uuid) {
      if (uuid) {
        this.getData(uuid)
      }
    },
  },
  async mounted() {
    
  },
  methods: {
   
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          for (let field in data) {
            data[field] = data[field];
            if (data[field] != null && data[field].value) {
              data[field] = data[field].value
            }
          }
          
          if (this.model.uuid) {
            response = await this.$http.put(`web-software/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post('web-software', data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('WebSoftware salvo com sucesso.')
          this.$emit('onSave', true)
          this.resetModel()
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.resetModel()
      this.$refs.refFormObserver.reset()
    },
    async getData(uuid) {
      const response = await this.$http.get(`web-software/${uuid}`)
      this.model = response

    },
    resetModel() {
      this.model = {
        uuid: null,
        name: '',

      }
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>

<style scoped>

</style>

